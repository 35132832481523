import { FC, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { DataGrid, GridColDef, GridCellParams } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';

import { BACKEND_URL } from '../config'
import { fetchWithAuthMethod, fetchAllData } from '../utils'
import { formatUnixTime } from '../utils/dateUtils'
import { AntennaIcon } from './AntennaIcon';
import { BatteryIcon } from './BatteryIcon';


export const Devices: FC = () => {
  const [devicesWithChannels, setDevicesWithChannels] = useState<any[]>([]);
  const [selectedDeviceId, setSelectedDeviceId] = useState<string | null>(null); 
  const [deviceName, setDeviceName] = useState('');
  const [channelNames, setChannelNames] = useState<string[]>([]);
  const [channelIds, setChannelIds] = useState<string[]>([]);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [maxChannelCount , setMaxChannelCount ] = useState<number>(0);

  useEffect(() => {
    const fetchData = async () => {

      // デバイスとチャネル情報を取得
      const [devices, channels] = await Promise.all([
        fetchAllData(`${BACKEND_URL}/devices`),
        fetchAllData(`${BACKEND_URL}/channels`),
      ]);
      
      // デバイスにチャネル情報を結合
      const devicesWithChannels = devices.map((device: any) => {
        const deviceChannels = channels.filter((channel: any) => channel.device_id === device.id);

        // 現在の仕様では地点名称は最大2、device_idに3以上が存在すればその値を最大数とする
        const deviceChannelCount = Math.max(...deviceChannels.map((channel: any) => channel.device_channel_id), 2);
        setMaxChannelCount(deviceChannelCount);
        // チャネルIDとチャネル名称の配列を初期化
        const deviceChannelIds = Array(deviceChannelCount).fill(null);
        const deviceChannelNames = Array(deviceChannelCount).fill('未使用');
        // デバイスに結びつくチャネル情報があれば格納
        deviceChannels.forEach((channel: any) => {
          // device_channel_id からインデックスを計算 (例: device_channel_id = 1 の場合、インデックスは 0)
          const index = channel.device_channel_id - 1;
          if (0 <= index && index < deviceChannelCount) {
            deviceChannelIds[index] = channel.id;
            deviceChannelNames[index] = channel.name;
          }
        });
        
        return {
          id: device.id,
          ...device,
          lqi: device.state?.lqi,
          lastComTimestamp: new Date(device.state?.lastCom),
          lastBatt: device.state?.last_batt,
          channelIds: deviceChannelIds,
          channelNames: deviceChannelNames,
        };
      });

      setDevicesWithChannels(devicesWithChannels);      
    };

    fetchData().catch(console.error);
  }, []);

  const handleEditClick = async (row: any) => {
    setSelectedDeviceId(row.id); // デバイスのIDを状態に保存
    setDeviceName(row.name); // 端末名称をフォームにセット
    setChannelNames(row.channelNames); // 地点名称をフォームにセット
    setChannelIds(row.channelIds); // channelIdを記憶
    setOpenEditDialog(true); // 編集ダイアログを開く

    setTimeout(() => {
      document.getElementById('device-name-input')?.focus(); // IDを使ってフォーカス
    }, 0);
  };
  
  const handleEditNames = async () => {
    if (!selectedDeviceId) return; // デバイスIDがない場合は処理しない
    const index = devicesWithChannels.findIndex(devicesWithChannel => devicesWithChannel.id === selectedDeviceId);
    if (index < 0) return;

    // 確認メッセージを表示
    const confirmed = window.confirm('更新してもよろしいですか？');
    if (!confirmed) return; // ユーザーがキャンセルした場合は処理しない

    setIsLoading(true); // ローディング開始   
    
    let updatedOK = true;
    let updatedDeviceName = '';
    let updatedChannels = devicesWithChannels[index].channelNames;

    // 端末名称と地点名称を更新    
    const updateChannels = channelIds
      .map((id, idx) => ({ id: Number(id), name: channelNames[idx] }))
      .filter(channel => channel.id !== null && channel.id !== undefined && Number(channel.id) > 0);

    const response = await fetchWithAuthMethod(`${BACKEND_URL}/channels/update_names/${selectedDeviceId}`, `PUT`, {
      device_name: deviceName,
      channels_update: updateChannels
    });

    const responseData  = await response.json();
    if (response.ok) {            
      updatedDeviceName = responseData.device_name;

      const responseChannels = responseData.channels;
      const updatedNames = channelIds.map((id, idx) => {
        const updatedChannel = responseChannels.find((channel: any) => channel.id === id);
        return updatedChannel ? updatedChannel.name : channelNames[idx];
      });

      updatedChannels = updatedNames;
    } else {
      updatedDeviceName = devicesWithChannels[index].name;
      console.error('Failed to update channel', responseData);
      updatedOK = false;
    }

    // devicesWithChannels の中の特定のデバイスのみを更新
    setDevicesWithChannels(prevDeviceWithChannels => {
      const newDeviceWithChannels = [...prevDeviceWithChannels];
      if (0 <= index) {
        // 指定デバイスのデータのみ更新
        newDeviceWithChannels[index] = {
          id: selectedDeviceId, // idを明示的に設定
          name: updatedDeviceName,
          channelNames: updatedChannels,
          channelIds: devicesWithChannels[index].channelIds 
        };
      }
      return newDeviceWithChannels;
    });

    setIsLoading(false); // ローディング終了

    if (updatedOK) {
      handleCloseEditDialog(); // 編集ダイアログを閉じる
    }
  };

  const handleCloseEditDialog = (event?: React.SyntheticEvent<Element, Event> | {}, reason?: string) => {
    // 背景をクリックされるとダイアログの各値がクリアされてしまう対策
    if (reason && reason === 'backdropClick') {
      return;
    }

    setSelectedDeviceId(null);
    setDeviceName('');
    setChannelNames([]);

    setOpenEditDialog(false);
  };

  const getBatteryLevel = (voltage: number): number => {
    const calculatedLevel = Math.ceil((voltage / 0.37) * 0.5);
    return Math.min(calculatedLevel, 5);
  };

  // 地点名称の列を動的に生成
  const channelColumns = Array.from({ length: maxChannelCount }, (_, index) => ({
    field: `channel_name_${index + 1}`,
    headerName: `地点名称${index + 1}`,
    editable: false,
    width: 160,
    valueGetter: (params: GridCellParams) => params.row.channelNames[index] || '未使用',
  }));

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: '端末名称',
      editable: false,
      width: 160,
    },
    // 地点名称列
    ...channelColumns,
    {
      field: 'lastComTimestamp',
      headerName: '最終通信日時',
      headerAlign: 'left',
      type: 'date',
      align: 'right',
      width: 160,
      editable: false,
      valueFormatter: (params) => formatUnixTime(params.value),
    },
    {
      field: 'lqi',
      headerName: '回線品質',
      headerAlign: 'left',
      type: 'number',
      align: 'center',
      editable: false,
      width: 80,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => <AntennaIcon level={params.value}/>,
    },
    {
      field: 'lastBatt',
      headerName: '電池残量',
      headerAlign: 'left',
      type: 'number',
      align: 'center',
      editable: false,
      width: 80,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <Tooltip title={params.value ? `${params.value.toFixed(1)} V` : ""}>
            <div style={{ display: 'flex', alignItems: 'flex-end', height: '100%' }}>
              <BatteryIcon level={getBatteryLevel(params.value)} />
            </div>
          </Tooltip>
        );
      },
      //renderCell: (params) => <BatteryIcon level={getBatteryLevel(params.value)} />,
      //valueFormatter: (params) => params.value ? `${params.value.toFixed(1)} V` : "",
    },
    {
      field: 'edit',
      headerName: '編集',
      width: 60,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <IconButton aria-label="edit" onClick={() => handleEditClick(params.row)}>
          <EditIcon />
        </IconButton>
      ),
    },
  ];
  
  return (
    <Box sx={{ height: '80vh', width: '100%' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: 'calc(100vh - 250px)' }} id="datalist-grid">
        <DataGrid
          rows={devicesWithChannels}
          columns={columns}
          pageSize={100} // 1ページの表示件数
          rowsPerPageOptions={[100]}
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
          density="compact"
          autoHeight={false}
          getRowHeight={() => 'auto'}
          getRowId={(row) => row.id}
          sx={{ flexGrow: 1, overflowY: 'auto',
            "& .MuiDataGrid-cell:focus": {
              outline: "none",
            },
            "& .MuiDataGrid-cell:focus-within": {
              outline: "none",
            },
            "& .Mui-selected": {
              outline: "none",
            },
          }}
        />
      </Box>
      <Dialog open={openEditDialog} onClose={(event, reason) => handleCloseEditDialog(event || {}, reason)}>
        <DialogTitle>端末の編集</DialogTitle>
        <DialogContent>
          <TextField
            id="device-name-input" 
            autoFocus
            margin="dense"
            label="端末名称"
            fullWidth
            value={deviceName}
            onChange={(e) => setDeviceName(e.target.value)}
          />
         {channelIds.map((id, index) => (
            id !== null ? ( // channelIdsがnullでない場合にのみ表示
              <TextField
                key={index}
                margin="dense"
                label={`地点名称${index + 1}`}
                fullWidth
                value={channelNames[index]}
                onChange={(e) => {
                  const newChannelNames = [...channelNames];
                  newChannelNames[index] = e.target.value;
                  setChannelNames(newChannelNames);
                }}
              />
            ) : null
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCloseEditDialog()}>キャンセル</Button>
          {isLoading ? (
            <CircularProgress /> // MUIのスピナーを表示
          ) : (
            <Button 
              onClick={handleEditNames}
              disabled={
                (!deviceName || deviceName.trim().length === 0) || 
                channelIds.some((id, index) => id !== null && (!channelNames[index] || channelNames[index].trim().length === 0))
              }
            >
              更新
            </Button>
          )} 
        </DialogActions>
      </Dialog>
    </Box>
  );
}
