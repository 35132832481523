import { FC, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { BACKEND_URL } from '../config'
import { fetchAllData } from '../utils'
import { formatUnixTime } from '../utils/dateUtils'


const columns: GridColDef[] = [
  {
    field: 'name',
    headerName: '地点名称',
    editable: false,
    width: 160,
  },
  {
    field: 'lastValue',
    headerName: '最終計測値',
    headerAlign: 'left',
    type: 'number',
    align: 'right',
    editable: false,
    width: 140,
      valueFormatter: (params) => params.value ? `${params.value.toFixed(1)} ℃` : "",//TODO:
  },
  {
    field: 'lastTimestamp',
    headerName: '最終計測日時',
    headerAlign: 'left',
    type: 'date',
    align: 'right',
    editable: false,
    width: 160,
    valueFormatter: (params) => {
      return formatUnixTime(params.value);
    }
  },
];

export const Channels: FC = () => {
  const [channels, setChannels] = useState<any[]>([]);

  useEffect(() => {

    const fetchData = async () => {
      const channelsData = await fetchAllData(`${BACKEND_URL}/channels`);
      setChannels(
        channelsData .map((x: any) => ({
          ...x,
          lastValue: x.state?.last?.phy_value,
          lastTimestamp: new Date(x.state?.last?.time),
        }))
      );
    };
    
    fetchData()
    .catch(console.error);
  }, []);

  return (
    <Box sx={{ height: '80vh', width: '100%' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: 'calc(100vh - 250px)' }} id="datalist-grid">
        <DataGrid
          rows={channels}
          columns={columns}
          pageSize={100} // 1ページの表示件数
          rowsPerPageOptions={[100]}
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
          density="compact"
          sx={{ flexGrow: 1, overflowY: 'auto',
            "& .MuiDataGrid-cell:focus": {
              outline: "none",
            },
            "& .MuiDataGrid-cell:focus-within": {
              outline: "none",
            },
            "& .Mui-selected": {
              outline: "none",
            },
          }}
        />
      </Box>
    </Box>
  );
}
