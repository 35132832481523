import { FC, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef, GridSortModel } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';

import { BACKEND_URL } from '../config';
import { fetchWithAuth, fetchAllData } from '../utils';
import { formatUnixTime, toLocal } from '../utils/dateUtils'

const columns: GridColDef[] = [
  {
    field: 'timestamp',
    headerName: '計測日時',
    headerAlign: 'left',
    type: 'date',
    align: 'right',
    width: 160,
    editable: false,
    valueFormatter: (params) => {
      return formatUnixTime(toLocal(params.value));
    }
  },
  {
    field: 'value',
    headerName: '計測値',
    headerAlign: 'left',
    type: 'number',
    align: 'right',
    editable: false,
    width: 120,
    valueFormatter: (params) => {
      if (params.value) {
        return `${params.value} ℃`;
      }
      return "";
    }
  },
];

export const DataList: FC = () => {
  const [rows, setRows] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [channels, setChannels] = useState<any[]>([]);
  const [selectedChannelId, setSelectedChannelId] = useState<string | null>(null);
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'timestamp',
      sort: 'desc', // 初回表示時に'timestamp'で降順
    },
  ]);

  const fetchData = async (channelId: string | null = null) => {
    setLoading(true);
    try {
      const url = `${BACKEND_URL}/records?channel_id=${channelId}&limit=50&order_asc=False`;
  
      const records = await fetchWithAuth(url).then(res => res.json());

      const formattedRows: any[] = [];
      records.forEach((record: any) => {
        formattedRows.push({
          id: record.id,
          timestamp: new Date(record.timestamp),
          value: record.phy_value,
        });
      });

      setRows(formattedRows);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCannels = async () => {
    try {
      const channelsResonse = await fetchAllData(`${BACKEND_URL}/channels`);
      setChannels(channelsResonse);

      // デフォルトのチャネルIDを設定
      if (channelsResonse.length > 0) {
        setSelectedChannelId(channelsResonse[0].id);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchCannels();
  }, []);

  useEffect(() => {
    if (selectedChannelId) {
      fetchData(selectedChannelId);
    }
  }, [selectedChannelId]);

  const handleChannelChange = async (event: React.ChangeEvent<{ value: unknown }>) => {
    const channelId = event.target.value as string;
    setSelectedChannelId(channelId);

    await fetchData(channelId);
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleSortModelChange = (newSortModel: GridSortModel) => {
    setSortModel(newSortModel);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', maxHeight: '80vh', marginTop: 2, marginLeft: 2, marginRight: 2 }}>
      {/* チャネルのコンボボックス */}
      <TextField
        select
        margin="dense"
        label="地点名称"
        fullWidth
        value={selectedChannelId}
        onChange={handleChannelChange}
        InputLabelProps={{
          shrink: true,  // ラベルを常に表示するためのプロパティ
        }}
        SelectProps={{
          MenuProps: {
            PaperProps: {
              style: {
                width: 'auto',  // ドロップダウンメニューの幅を自動調整
                minWidth: '95%', // コンボボックスの幅に合わせる
              },
            },
          },
        }}
      >
        {channels.map((channel: any) => (
          <MenuItem key={channel.id} value={channel.id}>
            {channel.name}
          </MenuItem>
        ))}
      </TextField>
	  
      <Box sx={{ marginBottom: 2 }} />
      <Box sx={{ flexGrow: 1, marginBottom: 2 }}>
        <Backdrop sx={{ color: '#ccc', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Box sx={{ display: 'flex', flexDirection: 'column', height: 'calc(100vh - 250px)' }} id="datalist-grid">
          <DataGrid
            rows={rows}
            columns={columns}            
            pageSize={100} // 1ページの表示件数
            rowsPerPageOptions={[100]}
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            density="compact"
            autoHeight={false}
            getRowHeight={() => 'auto'}
            pagination
            paginationMode="server"
            page={currentPage}
            onPageChange={handlePageChange}
            loading={loading}
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            sx={{ flexGrow: 1, overflowY: 'auto',
              "& .MuiDataGrid-cell:focus": {
                outline: "none",
              },
              "& .MuiDataGrid-cell:focus-within": {
                outline: "none",
              },
              "& .Mui-selected": {
                outline: "none",
              },
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};